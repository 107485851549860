import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Link from "../utils/link"

import timesLightIcon from "../assets/images/times-light.svg"
import "./BottomBar.scss"

const BottomBar = ({ isFixed }) => {
  const [isVisible, setIsVisible] = React.useState(false)

  const data = useStaticQuery(graphql`
    query {
      wp {
        siteSettings {
          options {
            bottomBarText
          }
        }
      }
    }
  `)

  React.useEffect(() => {
    if (sessionStorage) {
      const isBottomBarClosed = sessionStorage.getItem("isBottomBarClosed")
      if (isBottomBarClosed) {
        setIsVisible(false)
      } else {
        setIsVisible(true)
      }
    }
  }, [])

  const closeBottomBar = () => {
    setIsVisible(false)
    sessionStorage.setItem("isBottomBarClosed", true)
  }

  if (isVisible) {
    return (
      <section className={`bottom-bar ${isFixed ? "is-fixed" : ""}`}>
        <div className="bottom-bar__container">
          <div className="bottom-bar__text">
            {data.wp.siteSettings.options.bottomBarText}
          </div>
          <div className="bottom-bar__actions">
            <div className="bottom-bar__links">
              <a
                href="#"
                onClick={(event) => {
                  event.preventDefault()
                  window.ubookr.addBooking()
                }}
              >
                Property Booking
              </a>
              <Link to="/contact">Contact</Link>
            </div>
            <div className="bottom-bar__close">
              <span onClick={closeBottomBar}>
                <img src={timesLightIcon} />
              </span>
            </div>
          </div>
        </div>
      </section>
    )
  } else {
    return null
  }
}

BottomBar.propTypes = {
  isFixed: PropTypes.bool,
}

BottomBar.defaultProps = {
  isFixed: false,
}

export default BottomBar
